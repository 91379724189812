import { useContext } from 'react';
import { settingsStore } from 'stores/settings';

// The supported date formats. The first 4 are compatible with Intl.DateTimeFormat and then there are a couple of added formats:
type DateFormat =
    | 'short' // Example: "2024/12/18" (in UK) or "12/18/2024" (in US)
    | 'medium' // Example: "2024/12/18, 13:28" (in UK) or "12/18/2024, 1:28 PM" (in US)
    | 'long' // Example: "28 February, 2024 at 18:59" (in UK) or "February 28, 2024 at 6:59 PM" (in US)
    | 'full' // Example: "Wednesday 28 February, 2024 at 18:59" (in UK) or "Wednesday February 28, 2024 at 6:59 PM" (in US)
    | 'monthAndYear' // Example: "Nov '25", for the month of November in the year 2025.
    | 'monthAndDay'; // Example: "15 Nov" (in UK) or "Nov 15" (in US), for the 15th day of November.

type Options = {
    includeTime?: boolean;
    format?: DateFormat;
};

export const useDateFormatting = () => {
    const { tenantTimezone, language } = useContext(settingsStore);

    const convertToUTC = (dateStr: string | number) => {
        // Split the date and time segments
        const [datePart, timePart] = dateStr.toString().split(' ');
        // Reformat from DD/MM/YYYY to YYYY-MM-DD for ISO format.
        const [year, month, day] = datePart.split('-');
        // Combine into ISO format and add 'Z' to indicate UTC
        return new Date(`${year}-${month}-${day}T${timePart}Z`);
    };

    const tenantDate = (
        date: string | number,
        options: Options = { includeTime: true, format: 'short' }
    ): string => {
        if (!date || typeof date === 'number') {
            return 'N/A';
        }

        // Check if 'date' has the ISO 8601 date format or not ("YYYY-MM-DDTHH:mm:ss.sssZ"):
        const isIsoUtc =
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/.test(date);

        // Make it independent of local timezone:
        const utcDate = isIsoUtc ? new Date(date) : convertToUTC(date);

        // Format the date, depending on the 'options.format' parameter and 'language':
        let formattedDate;
        switch (options.format) {
            case 'monthAndYear':
                // Special format for month and year:
                formattedDate = new Intl.DateTimeFormat(language, {
                    month: 'short',
                    year: '2-digit',
                    timeZone: tenantTimezone
                })
                    .format(utcDate)
                    .replace(/(\d{2})$/, "'$1"); // Add an apostrophe in front of the year.
                break;
            case 'monthAndDay':
                // Special format for month and day:
                formattedDate = new Intl.DateTimeFormat(language, {
                    month: 'short',
                    day: 'numeric',
                    timeZone: tenantTimezone
                }).format(utcDate);
                break;
            case 'short':
                // Intl.DateTimeFormat does not add the correct padding for us dates,
                // resulting in 9/9/2021 instead of 09/09/2021.
                // To fix this, we use a custom formatter for US dates:
                formattedDate = new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: options.includeTime ? '2-digit' : undefined,
                    minute: options.includeTime ? '2-digit' : undefined,
                    hour12: options.includeTime && language === 'en-US' ? true : false,
                    timeZone: tenantTimezone
                }).format(utcDate);
            default:
                // The default formatter:
                formattedDate = new Intl.DateTimeFormat(language, {
                    dateStyle: options.format,
                    timeStyle: options.includeTime ? 'short' : undefined,
                    timeZone: tenantTimezone
                }).format(utcDate);
        }

        // Apply the given format string:
        return formattedDate.replace('am', 'AM').replace('pm', 'PM');
    };

    return { tenantDate };
};
