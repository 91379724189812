import React from 'react';
import { ASTheme } from 'themes/styled';
import { ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import { activityStream } from '../themes';
import { AmountSpentBucketsDimension, FullDimension } from './dimensionData';
import { ASMapSettings } from 'utils/api/provisioning/ProvisioningAPI';
import { AS_LOGO_ICON } from 'assets/iconConstants';
import {
    PRODUCTS_CLASSIFICATION_CATEGORIES_FLAT,
    PRODUCTS_CLASSIFICATION_TYPE,
    PRODUCTS_PERFORMER_LABEL_FLAT,
    PRODUCTS_PROMOTER_LABEL_FLAT,
    TRANSACTIONS_HALL_LABEL,
    TRANSACTIONS_PRODUCTION_LABEL,
    TRANSACTIONS_SUBTENANT,
    TRANSACTIONS_VENUE_LABEL
} from 'utils/common/constants';
import { User } from 'utils/api/userManagement/types';

interface Action {
    type: string;
    payload:
        | string
        | ASTheme
        | string[]
        | { [key: string]: string }
        | boolean
        | FeatureFlags
        | EmailSettings
        | ASMapSettings
        | MixpanelSettings
        | User;
}

export interface FeatureFlag {
    name: string;
    isEnabled: boolean;
}

export interface EmailSettings {
    unlayerProjectId: string;
    unlayerUserId: string;
    sendingDomain: string;
}

export interface MixpanelSettings {
    APIKey: string;
    projectName: string;
}

export interface FeatureFlags {
    useSply: boolean;
    showInventory: boolean;
    useMiles: boolean;
    showProductionKPI: boolean;
    showResales: boolean;
    shortListTopList: boolean;
    complimentary: boolean;
    allowExport: boolean;
    allowScopedUsersExport: boolean;
    inlineManual: boolean;
    disablePeriodSelector: boolean;
    showUplifts: boolean;
    showCustomersTable: boolean;
    showFocusedButton: boolean;
    showProductSalesOverviewProductDropdowns: boolean;
    eventBlocks: boolean;
    defaultDateRangeEndOfMinute: boolean;
    realTimeAudienceRewards: boolean;
    hubspotReports: boolean;
    hideNotOptIn: boolean;
    showGroupedSalesByCampaign: boolean;
    showPriceCategoryInventory: boolean;
    hideHoldAndReserved: boolean;
}

export interface State {
    language: string;
    theme: Theme;
    dictionary: { [key: string]: string };
    tenantIcon: string;
    tenant: string;
    tenantDisplayName: string;
    tenantTimezone: string;
    pii: boolean;
    featureFlags: FeatureFlags;
    emailSettings: EmailSettings;
    mixpanelSettings: MixpanelSettings;
    availableService: string[] | null;
    availableDashboards: string[] | null;
    amountSpentBucketsDimension: AmountSpentBucketsDimension;
    excludeDashboardComponent: string[];
    currentUserRoles: string[];
    currentUserScope: string[];
    dispatch: React.Dispatch<Action>;
    mapSettings: ASMapSettings;
    subtenants: { [key: string]: string };
    partitions: { [key: string]: string };
    dashboardSettings: {
        event_status_overview: {
            filters: FullDimension[];
        };
    };
    currentUser: User | null;
    env: string | undefined;
    excludeSegments: string[];
    sparkpostSendingDomains: string[];
    defaultDashboard: string | undefined;
    focusedSegments: boolean;
}

export const defaultFeatureFlags: FeatureFlags = {
    useSply: true,
    showInventory: true,
    useMiles: true,
    showProductionKPI: true,
    showResales: true,
    shortListTopList: false,
    complimentary: false,
    allowExport: true,
    allowScopedUsersExport: false,
    inlineManual: false,
    disablePeriodSelector: false,
    showUplifts: false,
    showCustomersTable: true,
    showFocusedButton: true,
    showProductSalesOverviewProductDropdowns: false,
    eventBlocks: false,
    defaultDateRangeEndOfMinute: false,
    realTimeAudienceRewards: false,
    hubspotReports: false,
    hideNotOptIn: false,
    showGroupedSalesByCampaign: false,
    showPriceCategoryInventory: true,
    hideHoldAndReserved: false
};

export const defaultEmailSettings = {
    unlayerProjectId: '',
    unlayerUserId: '',
    sendingDomain: 'activitystream.com'
};

export const defaultMixpanelSettings = {
    APIKey: '',
    projectName: ''
};

const initialState: State = {
    tenant: '',
    tenantDisplayName: '',
    tenantTimezone: 'UTC',
    pii: false,
    language: 'en',
    theme: createTheme(activityStream),
    dictionary: {},
    tenantIcon: AS_LOGO_ICON,
    featureFlags: defaultFeatureFlags,
    emailSettings: defaultEmailSettings,
    mixpanelSettings: defaultMixpanelSettings,
    mapSettings: {
        zoomLevels: {}
    },
    availableService: null,
    availableDashboards: null,
    amountSpentBucketsDimension: 'amountSpentBuckets250',
    excludeDashboardComponent: [],
    currentUserRoles: [],
    currentUserScope: [],
    subtenants: {},
    partitions: {},
    dashboardSettings: {
        event_status_overview: {
            filters: [
                PRODUCTS_CLASSIFICATION_TYPE,
                PRODUCTS_CLASSIFICATION_CATEGORIES_FLAT,
                TRANSACTIONS_PRODUCTION_LABEL,
                TRANSACTIONS_SUBTENANT,
                PRODUCTS_PROMOTER_LABEL_FLAT,
                TRANSACTIONS_VENUE_LABEL,
                TRANSACTIONS_HALL_LABEL,
                PRODUCTS_PERFORMER_LABEL_FLAT
            ]
        }
    },
    currentUser: null,
    env: undefined,
    excludeSegments: [],
    sparkpostSendingDomains: [],
    defaultDashboard: undefined,
    focusedSegments: false,
    dispatch: () => {}
};
const store = React.createContext<State>(initialState);
const { Provider } = store;

const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = React.useReducer((state = initialState, action: Action) => {
        switch (action.type) {
            case 'setFeatureFlags':
                return { ...state, featureFlags: action.payload };
            case 'setEmailSettings':
                return { ...state, emailSettings: action.payload };
            case 'setMixpanelSettings':
                return { ...state, mixpanelSettings: action.payload };
            case 'setLanguage':
                return { ...state, language: action.payload };
            case 'setTheme':
                const theme = action.payload as ASTheme;
                return { ...state, theme: createTheme(theme) };
            case 'setDictionary':
                return { ...state, dictionary: action.payload };
            case 'setAvailableService':
                return { ...state, availableService: action.payload };
            case 'setAvailableDashboards':
                return { ...state, availableDashboards: action.payload };
            case 'setAmountSpentBucketsDimension':
                return { ...state, amountSpentBucketsDimension: action.payload };
            case 'setTenant':
                return { ...state, tenant: action.payload };
            case 'setTenantDisplayName':
                return { ...state, tenantDisplayName: action.payload };
            case 'setTimeZone':
                return { ...state, tenantTimezone: action.payload };
            case 'setExcludeDashboardComponent':
                return { ...state, excludeDashboardComponent: action.payload };
            case 'setPii':
                return { ...state, pii: action.payload };
            case 'setCurrentUserRoles':
                return { ...state, currentUserRoles: action.payload };
            case 'setCurrentUserScope':
                return { ...state, currentUserScope: action.payload };
            case 'setMapSettings':
                return { ...state, mapSettings: action.payload };
            case 'setSubtenants': {
                return { ...state, subtenants: action.payload };
            }
            case 'setPartitions': {
                return { ...state, partitions: action.payload };
            }
            case 'setTenantIcon': {
                return { ...state, tenantIcon: action.payload };
            }
            case 'setCurrentUser': {
                return { ...state, currentUser: action.payload };
            }
            case 'setEnv': {
                return { ...state, env: action.payload };
            }
            case 'setExcludeSegments': {
                return { ...state, excludeSegments: action.payload };
            }
            case 'setSparkpostSendingDomains': {
                return { ...state, sparkpostSendingDomains: action.payload };
            }
            case 'setDefaultDashboard': {
                return { ...state, defaultDashboard: action.payload };
            }
            case 'focusedSegments': {
                return { ...state, focusedSegments: action.payload };
            }
            default:
                return state;
        }
    }, initialState);
    const {
        language,
        theme,
        tenantIcon,
        availableDashboards,
        availableService,
        amountSpentBucketsDimension,
        excludeDashboardComponent,
        currentUserRoles,
        currentUserScope,
        tenant,
        tenantDisplayName,
        tenantTimezone,
        pii,
        featureFlags,
        emailSettings,
        mixpanelSettings,
        mapSettings,
        subtenants,
        partitions,
        dashboardSettings,
        currentUser,
        env,
        excludeSegments,
        sparkpostSendingDomains,
        defaultDashboard,
        dictionary,
        focusedSegments
    } = state;

    React.useEffect(() => {
        Object.entries(state.theme.variables as Theme['variables']).forEach(([variable, value]) => {
            document.documentElement.style.setProperty(variable, value);
        });
    }, [state]);

    return (
        <ThemeProvider theme={theme}>
            <Provider
                value={{
                    language,
                    theme,
                    dictionary,
                    tenantIcon,
                    featureFlags,
                    emailSettings,
                    mixpanelSettings,
                    availableDashboards,
                    availableService,
                    amountSpentBucketsDimension,
                    excludeDashboardComponent,
                    currentUserRoles,
                    currentUserScope,
                    tenant,
                    tenantDisplayName,
                    tenantTimezone,
                    mapSettings,
                    subtenants,
                    partitions,
                    pii,
                    dispatch,
                    dashboardSettings,
                    currentUser,
                    env,
                    excludeSegments,
                    sparkpostSendingDomains,
                    defaultDashboard,
                    focusedSegments
                }}>
                {children}
            </Provider>
        </ThemeProvider>
    );
};

export { SettingsProvider, store as settingsStore };
